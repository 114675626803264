<template>
  <header
    class="header"
    :class="[
      { 'is-open' : isMenuOpen },
      { 'is-expand': isExpand },
      { 'is-upscroll': isUpscroll },
      { 'is-sticky': isSticky },
      { 'has-entered': upscrollEntered },
      { 'has-exited': upscrollExited },
    ]">
    <div v-if="global.show_header_alert" class="header__top" ref="headerTop">
      <div class="row">
        <div class="column small-12 large-6">
          <VueSlickCarousel v-bind="sliderSettings" class="header__top__slider" :class="{ 'enable-slider' : headerAlertLeft.length > 1 }">
            <template #prevArrow="arrowOption">
              <button>
                <SvgIcon name="chevron-left" title="Previous" />
              </button>
            </template>
            <prismic-rich-text
              v-for="(alert, i) in headerAlertLeft"
              :field="locale == 'en-ca' ? alert.text : (alert.text_us ? alert.text_us : alert.text)"
              :key="i"
            />
            <template #nextArrow="arrowOption">
              <button>
                <SvgIcon name="chevron-right" title="Next" />
              </button>
            </template>
          </VueSlickCarousel>
        </div>
        <div class="column small-12 large-6">
          <ArrowLink
            v-if="global.header_alert_right_text"
            :to="localStudioServiceLink"
            :text="global.header_alert_right_text" />
        </div>
      </div>
    </div>

    <div class="header__bottom">
      <component
        :is="isHomepage ? 'a' : 'nuxt-link'"
        href="#__nuxt"
        v-smooth-scroll
        to="/"
        class="header__logo">
        <transition>
          <SvgIcon v-if="isUpscroll || isSticky" name="g" :title="$config.siteName" key="g" />
          <SvgIcon v-else name="logo" :title="$config.siteName" key="logo" />
        </transition>
      </component>

      <Desktop :dropdownOnHover="dropdownOnHover" />
      <Mobile />
    </div>

    <MobileNav />
    <MobileAccountNav />

    <Cart />

    <Login />
    <ForgotPassword />
    <Register />

    <LeaveAReview />

    <Search />

    <transition>
      <div
        v-if="isMenuOpen"
        class="header__overlay"
        @click="closeHeaderMenu"
        @mouseenter="overlayMouseenter"
      ></div>
    </transition>
  </header>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel.css';

  import { mapState, mapGetters, mapActions } from 'vuex'

  import ArrowLink from '@/components/global/ArrowLink'
  import SvgIcon from '@/components/global/SvgIcon'

  export default {
    name: 'Header',

    components: {
      VueSlickCarousel,
      ArrowLink,
      SvgIcon,
      Mobile: () => import('@/components/header/Mobile'),
      Desktop: () => import('@/components/header/Desktop'),
      Cart: () => import('@/components/header/expands/Cart'),
      Login: () => import('@/components/header/expands/Login'),
      Search: () => import('@/components/header/expands/Search'),
      Register: () => import('@/components/header/expands/Register'),
      MobileNav: () => import('@/components/header/expands/MobileNav'),
      ForgotPassword: () =>
        import('@/components/header/expands/ForgotPassword'),
      LeaveAReview: () => import('@/components/header/expands/LeaveAReview'),
      MobileAccountNav: () =>
        import('@/components/header/expands/MobileAccountNav'),
    },

    data() {
      return {
        sliderSettings: {
          fade: true,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          vertical: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                autoplay: true,
                autoplaySpeed: 3500,
                infinite: true
              }
            }
          ]
        },
        dropdownOnHover: true,
        isUpscroll: false,
        upscrollEntered: false,
        upscrollExited: false,
        isSticky: false
      }
    },

    computed: {
      ...mapState({
        global: state => state.globalData,
        shop: state => state.header.shop,
        isMenuOpen: state => state.header.isMenuOpen,
        isExpand: state => state.header.isExpand,
        isDropdown: state => state.header.isDropdown,
        activeMenu: state => state.header.activeMenu,
        isModalOpen: state => state.modal.isOpen,
        locale: state => state.locale
      }),
      ...mapGetters(['localStudioServiceLink']),
      isHomepage() {
        return this.$route.name == 'home'
      },
      headerAlertLeft() {
        return this.global.header_alert_left;
      }
    },

    methods: {
      ...mapActions(['closeHeaderMenu']),
      isDesktop() {
        const w =
          window.innerWith ||
          document.documentElement.clientWidth ||
          document.body.clientWidth

        return w > 960
      },
      overlayMouseenter() {
        if (this.dropdownOnHover && this.isDropdown && this.isDesktop()) {
          this.closeHeaderMenu()
        }
      }
    },

    mounted() {
      // Closing menus on press of "ESC" key
      document.addEventListener('keyup', evt => {
        if (evt.keyCode === 27) {
          this.closeHeaderMenu()
        }
      })

      // var lastScrollTop = 0
      var headerTopHeight = this.$refs.headerTop.offsetHeight
      window.addEventListener('scroll', () => {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop

        if (scrollTop > headerTopHeight) {
          this.isSticky = true;
        } else {
          this.isSticky = false;
        }

        // Upscroll
        // if (scrollTop < headerTopHeight) {
        //   this.isUpscroll = false
        //   this.upscrollExited = false
        //   this.upscrollEntered = false
        // } else if (scrollTop < lastScrollTop) {
        //   this.isUpscroll = true
        //
        //   setTimeout(() => {
        //     this.upscrollExited = false
        //     this.upscrollEntered = true
        //   }, 310)
        // } else {
        //   this.upscrollExited = true
        //   this.upscrollEntered = false
        //
        //   setTimeout(() => {
        //     this.isUpscroll = false
        //     this.upscrollExited = false
        //   }, 310)
        // }
        //
        // lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
      }, false)
    },
  }
</script>
